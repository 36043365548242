<template>
  <h2>Περιγραφή Παιχνιδιού</h2>
  <p>
    Το παιχνίδι Highlight Games Soccerbet Game αποτελεί μοναδική προσομοίωση λογισμικού τυχαία επιλεχθέντων καλύτερων
    στιγμιότυπων ποδοσφαίρου από αγώνες για τη δημιουργία ευκαιριών παιχνιδιού. 
  </p>
  <h2>Περιγραφή και Έκταση Παιχνιδιού</h2>
  <p>
    Ένας γύρος ηλεκτρονικού παιχνιδιού στοιχήματος διαρκεί 5 λεπτά κατά μέσο όρο, και περιλαμβάνει τις ανακοινώσεις
    προγραμματισμένων αγώνων για εκείνη την εβδομάδα παιχνιδιού, την προβολή και τα αποτελέσματα.
  </p>
  <p>
    Η επιλογή καλύτερων στιγμιότυπων αγώνων ποδοσφαίρου είναι ανεξάρτητη στατιστικά, τυχαία και μη προβλέψιμη, και
    παράγεται από Γεννήτρια Τυχαίων Αριθμών (RNG).
  </p>
  <h2>Ειδικά Στοιχήματα (Αγορές)</h2>
  <h3>Ακριβές Σύνολο Γκολ</h3>
  <p>
    Η σημασία του Ακριβούς Αριθμού Γκολ Στοιχήματος έγκειται στο ότι πρέπει να σημειωθεί ένας ακριβής αριθμός γκολ σε
    έναν αγώνα. ... Επομένως, η συγκεκριμένη στοιχηματική παραλλαγή σημαίνει ότι καλείσαι να προβλέψεις έναν
    συγκεκριμένο αριθμό γκολ, παρά το τελικό αποτέλεσμα ολόκληρου του αγώνα.
  </p>
  <h3>Ακριβές Σύνολο Γκολ Γηπεδούχου</h3>
  <p>Στοίχημα επί του ακριβούς αριθμού γκολ που θα σημειώσει η Γηπεδούχος σε ένα παιχνίδι.</p>
  <h3>Ακριβές Σύνολο Γκολ Φιλοξενούμενης</h3>
  <p>Στοίχημα επί του ακριβούς αριθμού γκολ που θα σημειώσει η Φιλοξενούμενη σε ένα παιχνίδι.</p>
  <h3>Σκοράρουν και οι Δύο Ομάδες</h3>
  <p>
    Προκειμένου να κερδίσει το εν λόγω στοίχημα (BTTS), και οι δύο ομάδες χρειάζεται να σκοράρουν τουλάχιστον ένα γκολ,
    σημειώνοντας οποιοδήποτε αποτέλεσμα από 1-1- και πάνω. ... Το στοίχημα «Σκοράρουν και οι Δύο Ομάδες» δίνει στους
    παίκτες την ευκαιρία να επωφεληθούν από τέτοια γεγονότα προκειμένου να έχουν επικερδείς αποδόσεις όταν μόνο μία από
    τις δύο ομάδες καταφέρει να σκοράρει.
  </p>
  <h3>Ακριβές Σκορ</h3>
  <p>
    Το στοίχημα Ακριβούς Σκορ αποτελεί στοιχηματισμό σε αυτό που πιστεύεις ότι θα είναι το τελικό αποτέλεσμα ενός
    ποδοσφαιρικού αγώνα, με μέγιστο αριθμό τα 6 γκολ σε ένα παιχνίδι και 28 πιθανά αποτελέσματα.
  </p>
  <h3>Διπλή Ευκαιρία</h3>
  <p>
    Το στοίχημα διπλής ευκαιρίας σου επιτρέπει να καλύψεις με ένα στοίχημα δύο από τα τρία πιθανά αποτελέσματα ενός
    ποδοσφαιρικού αγώνα. Γηπεδούχος και ισοπαλία - Το στοίχημά σου κερδίζει εάν η γηπεδούχος κερδίσει τον αγώνα ή έρθει
    ισοπαλία. Φιλοξενούμενη και ισοπαλία - Το στοίχημά σου κερδίζει εάν η φιλοξενούμενη κερδίσει τον αγώνα ή έρθει
    ισοπαλία.
  </p>
  <h3>Αποτέλεσμα Αγώνα (1X2)</h3>
  <p>Στοίχημα να κερδίσει η γηπεδούχος (1), να κερδίσει η φιλοξενούμενη (2) ή ο αγώνας να τελειώσει με ισοπαλία (Χ).</p>
  <h3>Αποτέλεσμα Αγώνα και Σκοράρουν και οι Δύο Ομάδες (1X2 + BTTS)</h3>
  <p>
    Στοίχημα επί του αποτελέσματος του αγώνα (το 1 είναι νίκη γηπεδούχου, το Χ είναι ισοπαλία, το 2 είναι νίκη
    φιλοξενούμενης) και επί του εάν θα σκοράρουν και οι δύο ομάδες ή όχι.  
  </p>
  <ul>
    <li>1 + Γκολ - Η γηπεδούχος κερδίζει και σκοράρουν και οι δύο ομάδες τουλάχιστον ένα γκολ (π.χ. 3-1)  </li>
    <li>1 + Κανένα Γκολ - Η γηπεδούχος κερδίζει και είναι η μόνη ομάδα που σκοράρει γκολ (π.χ. 1-0)  </li>
    <li>Χ + Γκολ - Στοίχημα σε ισοπαλία και στο να σκοράρουν και οι δύο ομάδες (π.χ. 1-1 , 2 -2).  </li>
    <li>Χ + Κανένα Γκολ - Στοίχημα σε ισοπαλία και στο να μη σκοράρει καμία ομάδα  (μόνο 0 -0).  </li>
    <li>2 + Γκολ - Η φιλοξενούμενη κερδίζει και σκοράρουν και οι δύο ομάδες τουλάχιστον ένα γκολ (π.χ. 1-2, 1-3). </li>
    <li>2 + Κανένα Γκολ - Η φιλοξενούμενη κερδίζει και είναι η μόνη ομάδα που σκοράρει γκολ (π.χ. 0-1, 0-2).</li>
  </ul>
  <p> </p>
  <p></p>

  <h3>Αποτέλεσμα Αγώνα και Over/Under 1,5 Γκολ</h3>
  <p>Στοίχημα επί του αποτελέσματος του αγώνα από 1Χ2 και ο συνολικός αριθμός γκολ να είναι under ή over 1,5 γκολ.</p>
  <h3>Αποτέλεσμα Αγώνα και Over/Under 2,5 Γκολ</h3>
  <p>Στοίχημα επί του αποτελέσματος του αγώνα από 1Χ2 και ο συνολικός αριθμός γκολ να είναι under ή over 2,5 γκολ.</p>
  <h3>Σύνολο Γκολ (1 έως 2, 1 έως 3, 1 έως 4, 2 έως 3, 3 έως 4)</h3>
  <p>
    Στοίχημα ότι ο αγώνας θα έχει ως τελικό αποτέλεσμα συνολικό αριθμό γκολ 1 έως 2, 1 έως 3, 1 έως 4 ή 2 έως 4. Αυτό
    είναι στοίχημα που δηλώνεται με ΝΑΙ ή ΌΧΙ.
  </p>
  <h3>Γκολ Γηπεδούχου Over/Under 1,5</h3>
  <p>Στοίχημα ότι η Γηπεδούχος θα σκοράρει είτε λιγότερα είτε περισσότερα από 1,5 γκολ στον αγώνα.</p>
  <h3>Γκολ Φιλοξενούμενης Over/Under 1,5</h3>
  <p>Στοίχημα ότι η Φιλοξενούμενη θα σκοράρει είτε λιγότερα είτε περισσότερα από 1,5 γκολ στον αγώνα.</p>
  <h3>Σύνολο Γκολ Over/Under 1,5, 2,5, 3,5, 4,5</h3>
  <p>
    Στοίχημα ότι ο συνολικός αριθμός γκολ που θα σκοράρουν στον αγώνα και οι δύο ομάδες θα είναι under ή over 1,5, 2,5,
    3,5 ή 4,5.
  </p>
</template>
